import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  styled,
  Typography,
} from '@mui/material';
import { ReactComponent as Group } from 'assets/images/Group.svg';
import { ReactComponent as Upload } from 'assets/images/upload.svg';
import Loader from 'components/atoms/Loader';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useSyncedFields } from 'hooks/useDiscountCalculator';
import Cookies from 'js-cookie';
import { cloneDeep } from 'lodash';
import { DropzoneArea } from 'material-ui-dropzone';
import { useEffect, useMemo, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import {
  addCreditNotes,
  editCreditNotes,
  getCreditNotesDetails,
  getInvoicematerials,
  getListInvoice,
  getMaterial,
  getSupplierList,
} from 'redux/materials';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { DECIMAL_REGEX } from 'utils/constant';
import * as Yup from 'yup';

import MaterialRow from './Components/MaterialRow';

const CancelButton = styled(Button)`
  && {
    background-color: #edf1fe;
    color: #4872f4;
    border-radius: 12px;
    margin-right: 10px;
    padding: 11px 24px 11px 24px;
    text-transform: none;
    &:hover {
      background: edf1fe;
    }
  }
`;

const SaveButton = styled(Button)`
  && {
    background-color: #4872f4;
    color: #f6f7fb;
    border-radius: 12px;
    padding: 11px 24px 11px 24px;
    text-transform: none;
    &:hover {
      background: #4872f4;
    }
  }
`;

const GetButton = styled(Button)`
  && {
    background-color: #4872f4;
    color: #f6f7fb;
    border-radius: 12px;
    padding: 11px 24px 11px 24px;
    text-transform: none;
    height: fit-content;
    margin-top: 20px;
    &:hover {
      background: #4872f4;
    }
  }
`;

const HeadingFont = styled(Typography)`
  && {
    font-size: 28px;
    weight: 600;
    color: #3d3f43;
  }
`;

const CardHeaderFont = styled(Typography)`
  && {
    font-size: 22px;
    weight: 600;
    color: #3d3f43;
  }
`;

const RenderForm = props => {
  const {
    formikProps,
    invoiceListOptions,
    supplierOptions,
    project_id,
    getInvoiceMaterialData,
    handleSupplierChange,
    creditNotesDetails,
    EDIT,
  } = props;
  const [photoIndex, setphotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { values, handleChange, handleBlur, touched, errors, setFieldValue } = formikProps;

  const handleRemoveMaterial = (index: number) => {
    const updatedMaterial = [...values.materials];
    updatedMaterial.splice(index, 1);

    setFieldValue('materials', updatedMaterial);
  };

  const [uploadDialog, setUploadDialog] = useState(false);

  const toggleUpload = () => {
    setUploadDialog(v => !v);
  };

  const handleAddMaterial = () => {
    setFieldValue('materials', [
      ...values.materials,
      {
        id: values.materials.length + 1,
        mateial_category_id: '',
        material_sub_category_id: '',
        unit_title: '',
        quantity: 0,
        rate: 0,
        total_amount: 0,
      },
    ]);
  };

  const handleUpdateMaterialData = (index: number, field: string, value) => {
    const updatedMaterials = cloneDeep(values.materials);

    updatedMaterials[index][field] = value;

    const qty = Number(updatedMaterials[index].quantity) || 0;
    const rate = Number(updatedMaterials[index].rate) || 0;

    updatedMaterials[index].total_amount = Number((qty * rate).toFixed(2));

    setFieldValue('materials', updatedMaterials);
  };

  const validateDiscounts = (event, type) => {
    const value = event.target.valueAsNumber;
    const cleanedValue = isNaN(value) || value < 0 ? 0 : value;

    if (DECIMAL_REGEX.test(cleanedValue)) {
      if (type === 'amount') {
        materialDiscount.onChangeAmount(event);
      }
      if (type === 'percent') {
        materialDiscount.onChangePercent(event);
      }
    }
  };

  useEffect(() => {
    handleMaterialsTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.materials]);

  useEffect(() => {
    setFieldValue('total', (values.sub_total - values.discount_amount).toFixed(2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.sub_total, values.discount_amount]);

  const handleMaterialsTotal = () => {
    let sub_total = 0;

    values?.materials?.forEach(material => {
      sub_total += material.total_amount || 0;
    });

    setFieldValue('sub_total', sub_total);

    return sub_total?.toFixed(2);
  };

  const materialDiscount = useSyncedFields(
    values.sub_total,
    'discount_amount',
    'discount_percent',
    setFieldValue,
  );

  const handleUpload = files => {
    const attachmentsDoc = [...values.attachements];
    if (files.length) {
      attachmentsDoc.push(files);
    }
    setFieldValue('attachements', attachmentsDoc.flat());
  };

  const onClickHandlerClose = () => {
    setIsOpen(false);
  };

  const onImageClick = index => {
    setphotoIndex(index);
    setIsOpen(true);
  };

  return (
    <div className="shwan-form">
      {/* section 1 Supplier Details */}
      <Paper
        elevation={0}
        style={{
          paddingTop: 40,
          paddingBottom: 20,
          marginLeft: 20,
          borderRadius: 5,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={styles.wrapper}>
          <div className=" shwan-form ">
            <CardHeaderFont variant="h6">Supplier Details</CardHeaderFont>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '16px' }}>
              <div style={{ flex: '0.33', marginRight: '5px' }}>
                <div style={styles.headings}>
                  <Typography sx={{ color: '#53575B' }} variant="subtitle1">
                    Select Supplier
                  </Typography>
                  <div style={styles.redAsterisk}>
                    <Typography sx={{ ml: 1 }} variant="subtitle1">
                      *
                    </Typography>
                  </div>
                </div>
                <Select
                  closeMenuOnSelect={true}
                  name="supplier_id"
                  options={supplierOptions}
                  placeholder={
                    EDIT ? creditNotesDetails?.credit_notes?.supplier_name : 'Select Supplier'
                  }
                  styles={{
                    container: (base: any) => ({
                      ...base,
                      width: '31.5%',
                    }),
                  }}
                  value={values.supplier_name}
                  onChange={handleSupplierChange}
                />
                {errors.supplier_id && touched.supplier_id ? (
                  <div style={{ color: '#FF5D5D' }}>{errors.supplier_id}</div>
                ) : null}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: '20px',
                }}
              >
                <div style={{ flex: 1, marginRight: '10px' }}>
                  <div style={styles.headings}>
                    <Typography sx={{ color: '#53575B' }} variant="subtitle1">
                      Credit Note
                    </Typography>
                    <div style={styles.redAsterisk}>
                      <Typography sx={{ ml: 1 }} variant="subtitle1">
                        *
                      </Typography>
                    </div>
                  </div>
                  <input
                    className="form-control"
                    name="credit_note"
                    style={{ borderRadius: '2px' }}
                    type="text"
                    value={values.credit_note}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.credit_note && touched.credit_note ? (
                    <div style={{ color: '#FF5D5D' }}>{errors.credit_note}</div>
                  ) : null}
                </div>
                <div style={{ flex: 1 }}>
                  <div style={styles.headings}>
                    <Typography component="div" sx={{ color: '#53575B' }} variant="subtitle1">
                      Supplier Credit Date
                    </Typography>
                  </div>
                  <input
                    className="form-control"
                    name="credit_date"
                    style={{ borderRadius: '2px' }}
                    type="date"
                    value={values.credit_date}
                    onChange={handleChange}
                  />
                </div>
                <div style={{ flex: 1, marginRight: '10px', marginLeft: '10px' }}>
                  <div style={styles.headings}>
                    <Typography sx={{ color: '#53575B' }} variant="subtitle1">
                      Invoice Number
                    </Typography>
                  </div>
                  <Select
                    closeMenuOnSelect={true}
                    options={invoiceListOptions}
                    placeholder={
                      EDIT ? creditNotesDetails?.credit_notes?.invoice_no : 'Select Invoice No'
                    }
                    styles={{
                      container: (base: any) => ({
                        ...base,
                        width: '100%',
                      }),
                    }}
                    value={values.invoice_no}
                    onChange={e => setFieldValue('invoice_id', e.value)}
                  />
                </div>
                <GetButton onClick={getInvoiceMaterialData}>Get</GetButton>
              </div>
            </div>
          </div>
        </div>
      </Paper>
      {/* section 2  material Details*/}
      <Paper
        elevation={0}
        style={{
          borderRadius: 5,
          marginTop: 15,
          marginLeft: 20,
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '20px',
        }}
      >
        <div className="shwan-form mt-4">
          <div className="booking-form-col">
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: '25%' }}>Material</th>
                  <th style={{ width: '25%' }}>Sub-Material</th>
                  <th style={{ width: '10%' }}>Unit</th>
                  <th style={{ width: '10%' }}>Quantity</th>
                  <th className="text-right" style={{ width: '10%' }}>
                    Rate
                  </th>
                  <th className="text-right" style={{ width: '15%' }}>
                    Total Amount
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {values?.materials?.map((material, index) => {
                  return (
                    <MaterialRow
                      key={index}
                      EDIT={EDIT}
                      formikProps={formikProps}
                      handleRemoveMaterial={handleRemoveMaterial}
                      handleUpdateMaterialData={handleUpdateMaterialData}
                      index={index}
                      material={material}
                      project_id={project_id}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <Button
          style={{
            backgroundColor: '#edf1fe',
            marginTop: '10px',
            color: '#4872f4',
            borderRadius: '12px',
            marginLeft: '15px',
            width: '162px',
            height: '48px',
            padding: '11px 24px 11px 24px',
          }}
          onClick={handleAddMaterial}
        >
          Add More
        </Button>
      </Paper>
      {/* Section 3 Sub Total */}
      <Paper
        elevation={0}
        style={{
          borderRadius: 5,
          marginTop: 15,
          marginLeft: 20,
          display: 'flex',
          flexDirection: 'column',
          padding: '16px 16px 16px 16px',
          maxWidth: '100%',
        }}
      >
        <div>
          <div style={{ display: 'flex', gap: '24px' }}>
            <Typography sx={{ color: '#3D3F43' }}>Sub Total</Typography>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <Typography>:</Typography>
              <Typography>{`₹ ${values.sub_total}`}</Typography>
            </div>
          </div>

          <div style={{ display: 'flex', gap: '24px', marginTop: '20px', alignItems: 'center' }}>
            <Typography sx={{ mt: 1, color: '#3D3F43' }}>Discount</Typography>

            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <div>
                <span className="muted-text" style={{ fontSize: '12px' }}>
                  Amt.
                </span>
                <input
                  className="form-control"
                  name="discount_amount"
                  placeholder="0.00"
                  style={{
                    borderRadius: '2px',
                    marginRight: 2,
                    marginLeft: 0.5,
                    width: `calc(7.02vw - 5px)`,
                  }}
                  type="number"
                  value={values.discount_amount}
                  onChange={e => validateDiscounts(e, 'amount')}
                />
              </div>
              <div>
                <span className="muted-text" style={{ fontSize: '12px' }}>
                  %
                </span>
                <input
                  className="form-control"
                  name="discount_percent"
                  placeholder="%"
                  style={{ borderRadius: '2px', width: 'calc(5.02vw - 5px)' }}
                  type="number"
                  value={values.discount_percent}
                  onChange={e => validateDiscounts(e, 'percent')}
                />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '24px', marginTop: '25px' }}>
            <Typography sx={{ color: '#3D3F43' }}>Total</Typography>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginLeft: '33px' }}>
              <Typography>:</Typography>
              <Typography>{`₹ ${values.total}`}</Typography>
            </div>
          </div>
        </div>
      </Paper>
      {/* Section 3 Description */}
      <Paper
        elevation={0}
        style={{
          borderRadius: 5,
          marginTop: 15,
          marginLeft: 20,
          display: 'flex',
          flexDirection: 'column',
          padding: '16px 16px 16px 16px',
          maxWidth: '100%',
        }}
      >
        <CardHeaderFont sx={{ paddingBottom: '20px' }}>Description</CardHeaderFont>

        <textarea
          className="form-control"
          cols={100}
          name="description"
          rows={5}
          style={{ height: 'unset' }}
          value={values.description}
          onChange={handleChange}
        ></textarea>
      </Paper>
      {/* attachments */}
      <Paper
        elevation={0}
        style={{
          borderRadius: 5,
          marginTop: 15,
          marginLeft: 20,
          display: 'flex',
          flexDirection: 'column',
          padding: '16px 16px 16px 16px',
          maxWidth: '100%',
        }}
      >
        <CardHeaderFont sx={{ paddingBottom: '20px' }}>Attachments</CardHeaderFont>

        {values?.attachements?.length
          ? values.attachements?.map((attachment, index) => {
              return attachment?.type === 'application/pdf' ? (
                <a href={URL?.createObjectURL(attachment)} rel="noreferrer" target="_blank">
                  <div key={index} style={styles.discriptonSvgWrapper}>
                    <Group sx={{ fontSize: '20px' }} />
                    <div>
                      <Typography sx={{ size: '16px' }}>{attachment?.name}</Typography>
                      <Typography sx={{ size: '12px', color: '#5E6D7C' }}>
                        ({attachment?.size} Kb)
                      </Typography>
                    </div>
                  </div>
                </a>
              ) : (
                <div
                  key={index}
                  style={styles.discriptonSvgWrapper}
                  onClick={() => onImageClick(index)}
                >
                  <Group sx={{ fontSize: '20px' }} />
                  <div>
                    <Typography sx={{ size: '16px' }}>
                      {attachment?.name || attachment?.file_name}
                    </Typography>
                    <Typography sx={{ size: '12px', color: '#5E6D7C' }}>
                      ({attachment?.size || attachment?.file_size} Kb)
                    </Typography>
                  </div>
                </div>
              );
            })
          : undefined}

        <Dialog
          open={uploadDialog}
          sx={{
            '& .MuiDialog-paper': {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              maxWidth: 'max-content',
            },
          }}
          onClose={toggleUpload}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>Add Attachments</DialogTitle>
            <CancelIcon
              sx={{
                backgroundColor: '#FFE1E1',
                borderRadius: '80px',
                height: '28px',
                marginTop: '15px',
                marginRight: '15px',
                width: '28px',
                color: '#FF5D5D',
                cursor: 'pointer',
              }}
              onClick={toggleUpload}
            />
          </div>
          <Divider />
          <DialogContent>
            <DropzoneArea
              useChipsForPreview
              dropzoneClass="uploaderContainer"
              dropzoneParagraphClass={'uploaderText'}
              dropzoneText="Click here or drag & drop your file here"
              filesLimit={10}
              Icon={Upload}
              showAlerts={false}
              onChange={handleUpload}
            />
          </DialogContent>
          <DialogActions sx={{ height: '86px', width: '870px' }}>
            <Button
              style={{
                backgroundColor: '#4872f4',
                color: '#f6f7fb',
                borderRadius: '6px',
                marginRight: '10px',
                padding: ' 11px 24px 11px 24px',
                textTransform: 'none',
                height: '48px',
                width: '103px',
              }}
              onClick={toggleUpload}
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>

        {isOpen && (
          <Lightbox
            mainSrc={
              values?.attachements[photoIndex]?.file_url ||
              URL?.createObjectURL(values?.attachements[photoIndex])
            }
            onCloseRequest={onClickHandlerClose}
          />
        )}
        <Button
          disableElevation={true}
          style={{
            backgroundColor: '#edf1fe',
            marginTop: '10px',
            color: '#4872f4',
            borderRadius: '12px',
            width: '224px',
            height: '48px',
            padding: '11px 24px 11px 24px',
          }}
          variant="contained"
          onClick={toggleUpload}
        >
          <AddIcon style={{ fontSize: 'medium' }} /> Add Attachments
        </Button>
      </Paper>
    </div>
  );
};

const CreateCreditNotes = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { credit_notes_id } = location.state || {};

  const handleBack = () => {
    navigate(-1);
  };

  // url params
  // const project_id = searchParams.get('project_id');

  const project_id = Cookies.get('_hjproject_idUser_2492391');
  // const project_id = '17';

  const { supplierList, invoiceList, loading, creditNotesDetails } = useAppSelector(
    s => s.materials,
  );
  const EDIT = Boolean(credit_notes_id);

  useEffect(() => {
    dispatch(
      getSupplierList({
        project_id,
      }),
    );
    dispatch(
      getMaterial({
        project_id,
      }),
    );
    dispatch(
      getCreditNotesDetails({
        project_id,
        credit_notes_id,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSupplierChange = async e => {
    const supplierId = await e.value;
    formik.setFieldValue('supplier_id', supplierId);

    await dispatch(
      getListInvoice({
        project_id: Number(project_id),
        supplier_id: supplierId,
      }),
    );
  };

  const syncInvoiceMaterial = async () => {
    await dispatch(
      getInvoicematerials({
        project_id: Number(project_id),
        invoice_id: values.invoice_id,
      }),
    );
  };

  //Supplier List
  const supplierOptions = useMemo(() => {
    const suppliersOptions = supplierList?.map(e => ({
      label: `${e.supplier_name}`,
      value: e.id,
    }));
    return [...suppliersOptions];
  }, [supplierList]);

  //Invoice List
  const invoiceListOptions = useMemo(() => {
    const invoiceListsOptions = invoiceList?.map(e => ({
      label: `${e.invoice_no}`,
      value: e.id,
    }));
    return [...invoiceListsOptions];
  }, [invoiceList]);

  const initialValues = useMemo(() => {
    if (EDIT) {
      const { credit_notes, credit_notes_materials, credit_notes_attachements } =
        creditNotesDetails || {};
      const {
        created,
        credit_note,
        sub_total,
        total,
        discount_amount,
        description,
        discount_percent,
        invoice_id,
        supplier_id,
      } = credit_notes;
      return {
        credit_date: dayjs(created).format('YYYY-MM-DD'),
        credit_note,
        discount_amount,
        discount_percent,
        description,
        total,
        sub_total,
        materials: credit_notes_materials,
        attachements: credit_notes_attachements,
        invoice_id,
        supplier_id,
      };
    }
    return {
      supplier_id: 0,
      invoice_id: 0,
      credit_note: '',
      credit_date: dayjs().format('YYYY-MM-DD'),
      discount_amount: 0,
      description: '',
      discount_percent: 0,
      sub_total: 0,
      total: 0,
      materials: [],
      attachements: [],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EDIT]);

  const onSubmit = async values => {
    const {
      supplier_id,
      invoice_id,
      credit_note,
      credit_date,
      discount_amount,
      description,
      discount_percent,
      sub_total,
      total,
      materials,
      attachements,
    } = values;

    let res;

    const attachementsID = attachements?.filter(a => !a.id);

    if (EDIT) {
      res = await dispatch(
        editCreditNotes({
          project_id: Number(project_id),
          credit_notes_id,
          supplier_id,
          invoice_id,
          credit_note,
          credit_date,
          discount_amount,
          discount_percent,
          description,
          materials,
          attachements: attachementsID,
        }),
      );
    } else {
      res = await dispatch(
        addCreditNotes({
          project_id: Number(project_id),
          supplier_id,
          invoice_id,
          credit_note,
          credit_date,
          discount_amount,
          description,
          discount_percent,
          sub_total,
          total,
          materials,
          attachements,
        }),
      );
    }
    if (!res?.error) {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: onSubmit,
    validateOnChange: false,
    validationSchema: Yup.object({
      materials: Yup.array().of(
        Yup.object().shape({
          material_sub_category_id: Yup.string().required(`Sub Material is required field`),
        }),
      ),
    }),
  });

  const { handleSubmit, errors, values } = formik;

  // handling validations
  const validationErrors = useMemo(() => {
    const parsed = [];

    Object?.values(errors)?.map(error => {
      if (Array.isArray(error)) {
        error.map(err => {
          if (err) {
            Object?.values(err)?.map(v => {
              if (v && !parsed.includes(v)) {
                parsed.push(v);
              }
            });
          }
        });
      } else {
        if (error && !parsed.includes(error)) {
          parsed.push(error);
        }
      }
    });
    return parsed;
  }, [errors]);

  return (
    <>
      <Loader loading={loading} />
      <div
        style={{
          background: '#f6f7fb',
          zIndex: 50,
          position: 'sticky',
          top: 0,
          paddingTop: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={styles.mainHeading}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton style={{ color: '#041D36' }} onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
              <HeadingFont style={{ marginLeft: '5px' }}>Create Credit Notes</HeadingFont>
            </div>
          </div>
          <div style={styles.topBtn}>
            <CancelButton
              disableElevation={true}
              sx={{ ml: 2 }}
              variant="contained"
              onClick={() => navigate(-1)}
            >
              Cancel
            </CancelButton>
            <SaveButton disableElevation={true} variant="contained" onClick={() => handleSubmit()}>
              Save
            </SaveButton>
          </div>
        </div>
        {/* error box */}
        <Box className="w-90 mt-2">
          {validationErrors.length ? (
            <Box
              sx={{
                background: 'rgba(255, 93, 93, 0.1)',
                borderRadius: 2,
                padding: `5px`,
                fontSize: '1rem',
              }}
            >
              <ul>
                {validationErrors?.map((err, index) => {
                  return (
                    <li key={index}>
                      <Typography color={'#ff5d5d'} variant="h6">
                        {JSON.parse(JSON.stringify(err))}
                      </Typography>
                    </li>
                  );
                })}
              </ul>
            </Box>
          ) : undefined}
        </Box>
        <Divider sx={{ mt: 2 }} />
      </div>

      <Box sx={{ paddingRight: '20px', marginTop: '10px' }}>
        <Box className={'bookingFormUpdated shwan-form'}>
          <RenderForm
            creditNotesDetails={creditNotesDetails}
            EDIT={EDIT}
            formikProps={formik}
            getInvoiceMaterialData={syncInvoiceMaterial}
            handleSupplierChange={handleSupplierChange}
            invoiceListOptions={invoiceListOptions}
            project_id={project_id}
            supplierOptions={supplierOptions}
          />
        </Box>
      </Box>
    </>
  );
};

export default CreateCreditNotes;

const styles = {
  mainHeading: {
    marginLeft: '20px',
  },
  topBtn: {
    marginRight: '20px',
  },
  tableContainer: {
    margin: '40px',
  },
  headings: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  redAsterisk: {
    color: '#FF5D5D',
    marginLeft: '5px',
  },
  wrapper: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  materialHeadings: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  materialFields: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  container: {
    width: '200px',
  },

  discriptonSvgWrapper: {
    display: 'flex',
    gap: '8px',
    marginBottom: '10px',
    border: '1px',
    borderStyle: 'solid',
    width: 'fit-content',
    padding: '5px',
    borderRadius: '4px',
    borderColor: '#e8e8e8',
    alignItems: 'center',
  },
};
