import { Switch, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { useAppSelector } from 'redux/store';
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        // backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#5E6D7C',
    boxSizing: 'border-box',
  },
}));

const Label = styled(Typography)`
  color: #3d3f43;
  font-weight: 400;
  font-size: 18px;
`;

const Value = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  color: #041d36;
`;

const BrokerDetails = props => {
  const { broker_details, formikProps, brokerDetails, setBrokerDetails } = props;

  // const { first_name, last_name, email, phone } = broker_details || {};

  const { values, handleChange, setFieldValue } = formikProps;

  const { brokerList } = useAppSelector(s => s.sales);

  const brokerOptions = useMemo(() => {
    return brokerList?.map(e => ({
      label: `${e.first_name} - [${e.phone}${e.company_name ? ` - ${e.company_name}` : ''}]`,
      value: e.id,
      details: e,
    }));
  }, [brokerList]);

  useEffect(() => {
    if (values.broker_id) {
      const selectedBroker = brokerList.find(broker => broker.id === values.broker_id);
      if (selectedBroker) {
        setBrokerDetails(selectedBroker);
      }
    }
  }, [values.broker_id, brokerList, setBrokerDetails]);
  const selectedBroker = brokerOptions.find(broker => broker.value === values.broker_id);

  return (
    <div className="booking-form-box shwan-form mt-4">
      <div className="booking-form-col-12">
        <h5>Broker Details</h5>
        <div className="d-flex align-items-center justify-content-between">
          <label>Is this property being booked through a broker?</label>
          <Stack alignItems="center" direction="row" spacing={1}>
            <Typography color="#041D36">{values.through_broker ? 'Yes' : 'No'}</Typography>
            <AntSwitch
              checked={values.through_broker}
              // disabled={EDIT}
              inputProps={{ 'aria-label': 'ant design' }}
              onChange={event => setFieldValue('through_broker', event.target.checked)}
            />
          </Stack>
        </div>
      </div>

      {values.through_broker ? (
        <div className="booking-form-col-12 mt-1">
          <div className="form-row">
            <div className="col-12" style={{ display: 'flex', flexDirection: 'column' }}>
              <label style={{ fontSize: 16, fontWeight: 500 }}>Select broker</label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Select
                  placeholder={
                    broker_details
                      ? `${broker_details.first_name} - [${broker_details.phone}]`
                      : 'Existing Broker'
                  }
                  styles={{
                    container: (base: any) => ({
                      ...base,
                      width: '31%',
                      // marginTop: 10,
                      // marginBottom: 50,
                    }),
                  }}
                  value={selectedBroker}
                  onChange={e => (
                    setFieldValue('broker_id', e.value)
                  )}
                  closeMenuOnSelect={true}
                  // isDisabled={EDIT}
                  options={brokerOptions}
                />
                {/* {(!EDIT && brokerPermission?.add) ||
                  brokerPermission?.editor ||
                  brokerPermission?.approval ||
                  brokerPermission?.admin ? (
                    <button
                      className="Btn btn-lightblue-primary lbps-btn"
                      style={{ marginLeft: '10px' }}
                      type="button"
                      onClick={toggleBrokerModal}
                    >
                      <div className="d-flex align-items-center justify-content-center">
                        <svg
                          fill="none"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 5V19"
                            stroke="#4872F4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                          />
                          <path
                            d="M5 12H19"
                            stroke="#4872F4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                          />
                        </svg>
                        <span style={{ marginTop: 3, marginLeft: 5 }}> Add Broker</span>
                      </div>
                    </button>
                  ) : undefined} */}
              </div>
            </div>
          </div>

          {brokerDetails ? (
            <>
              <div className="form-row mt-3">
                <div className="form-group  col-sm-6 col-md-4 col-lg-6 col-xl-4">
                  <div style={{ display: 'flex', gap: '5rem', alignItems: 'center' }}>
                    <Label>Broker Name</Label>
                    <Value style={{ flex: 1 }}>
                      : <strong>{`${brokerDetails?.first_name}`}</strong>
                    </Value>
                  </div>
                </div>
                {brokerDetails?.company_name && (
                  <div className="form-group  col-sm-6 col-md-4 col-lg-6 col-xl-4">
                    <div style={{ display: 'flex', gap: '5rem', alignItems: 'center' }}>
                      <Label>Company Name</Label>
                      <Value style={{ flex: 1 }}>
                        : <strong>{`${brokerDetails?.company_name}`}</strong>
                      </Value>
                    </div>
                  </div>
                )}
                <div className="form-group  col-sm-6 col-md-4 col-lg-6 col-xl-4">
                  <div style={{ display: 'flex', gap: '5rem', alignItems: 'center' }}>
                    <Label>Phone Number</Label>
                    <Value style={{ flex: 1 }}>
                      : <strong>{brokerDetails?.phone} </strong>
                    </Value>
                  </div>
                </div>
                <div className="form-group col-sm-6 col-md-4 col-lg-8 col-xl-4">
                  <div className="broker-email-container">
                    <Label>Email Id</Label>
                    <Value style={{ flex: 1 }}>
                      : <strong>{brokerDetails?.email || ''} </strong>
                    </Value>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col">
                  <label>Brokerage amount</label>
                  <input
                    className="form-control"
                    name="brokerage"
                    style={{ width: '31%' }}
                    type="text"
                    value={values.brokerage}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col">
                  <label>Remark</label>
                  <textarea
                    className="form-control"
                    name="broker_remark"
                    value={values.broker_remark}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default BrokerDetails;
