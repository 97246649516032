import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AppBar, Box, Button, IconButton, styled, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { sendBookingFormOTP } from 'redux/sales';
import { useAppDispatch } from 'redux/store';

import OTPDialog from './OTPDialog';

const PageHeader = styled(AppBar)`
  background: #ffffff;
  box-shadow: none;
  color: #041d36;
  align-items: center;
  padding: 10px 20px 10px 40px;
  justify-content: space-between;
  border-bottom: 0.5px solid #d7d3d373;
  margin-top: 10px;
`;
const SaveButton = styled(Button)`
  && {
    background-color: #4872f4;
    color: #f6f7fb;
    border-radius: 12px;
    padding: 11px 24px 11px 24px;
    text-transform: none;
    &:hover {
      background: #4872f4;
    }
    &:disabled {
      background: #e7e7e7;
      color: #8e8e8e;
    }
  }
`;

const BookingHeader = props => {
  const { navigate, formikProps, customerDetails, project_id, isButtonDisabled } = props;

  const { errors, handleSubmit } = formikProps;
  const dispatch = useAppDispatch();
  const [isOTPSubmission, setOTPSubmission] = useState(false);

  // const SAVE_WITH_OTP_SWITCH = booking_validation?.form2_otp_on_off === 'yes' ? true : false;

  // handling validations
  const validationErrors = useMemo(() => {
    const parsed = [];

    Object?.values(errors)?.map(error => {
      if (Array.isArray(error)) {
        error.map(err => {
          if (err) {
            Object?.values(err)?.map(v => {
              if (v && !parsed.includes(v)) {
                parsed.push(v);
              }
            });
          }
        });
      } else {
        if (error && !parsed.includes(error)) {
          parsed.push(error);
        }
      }
    });
    return parsed;
  }, [errors]);

  const toggleDialog = () => {
    if (customerDetails?.phone) {
      dispatch(sendBookingFormOTP({ project_id, customer_phone: customerDetails?.phone }));
    }
    setOTPSubmission(v => !v);
  };

  const verifyFormOTP = async form_otp => {
    if (Number(form_otp) === Number()) {
      handleSubmit();
      setOTPSubmission(false);
    } else {
      toast.error('OTP does not match, Please try again!');
    }
  };
  return (
    <PageHeader position="sticky">
      <Box className="d-flex align-item-center justify-content-between w-100">
        <Box className="d-flex align-item-center">
          <IconButton
            style={{ backgroundColor: '#e5eafa', color: '#4872f4' }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography style={{ marginLeft: 12, paddingLeft: 10 }} variant="h5">
            Booking Form
          </Typography>
        </Box>
        <Box className="d-flex align-item-center">
          <SaveButton
            className="mr-4"
            // disabled={SAVE_WITH_OTP_SWITCH}
            disabled={isButtonDisabled}
            type="button"
            onClick={() => {
              handleSubmit();
            }}
          >
            Update
          </SaveButton>
          <button
            className="Btn btn-lightblue-primary lbps-btn m-0"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </button>

          {isOTPSubmission ? (
            <OTPDialog
              customerDetails={customerDetails}
              isOTPSubmission={isOTPSubmission}
              toggleDialog={toggleDialog}
              verifyFormOTP={verifyFormOTP}
            />
          ) : null}
        </Box>
      </Box>

      {/* error box */}
      <Box className="w-100 mt-2 mx-4">
        {validationErrors.length ? (
          <Box
            sx={{
              background: 'rgba(255, 93, 93, 0.1)',
              borderRadius: 2,
              padding: `5px`,
              fontSize: '1rem',
            }}
          >
            <ul>
              {validationErrors?.map((err, index) => {
                return (
                  <li key={index}>
                    <Typography color={'#ff5d5d'} variant="h6">
                      {JSON.parse(JSON.stringify(err))}
                    </Typography>
                  </li>
                );
              })}
            </ul>
          </Box>
        ) : undefined}
      </Box>
    </PageHeader>
  );
};

export default BookingHeader;
